import {navigateToUrl} from 'single-spa';

export type UrlStepType =
  | 'info'
  | 'survey'
  | 'consent'
  | 'idv'
  | 'enrollment'
  | 'unspecified';

// Parses the given param from the current url
export function getFromUrl(param: string): string | null {
  const params = new URL(window.location.href).searchParams;
  return params.get(param);
}

/**
 * Sets the url based on the provided parameters and optionally replaces the browser history.
 *
 * @param {Object} params - The parameters for setting the URL.
 * @param {string} params.bundleId - The bundle ID to set in the URL.
 * @param {string} params.stepId - The step ID to set in the URL.
 * @param {UrlStepType} params.stepType - The type of step (e.g., 'info', 'survey', 'consent').
 * @param {boolean} params.isRedirect - If true, replaces the browser history using replaceState; otherwise, uses navigateToUrl.
 *
 * @returns {void}
 */
export function setBundleStepUrl(params: {
  bundleId: string;
  stepId: string;
  stepType: UrlStepType;
  isRedirect: boolean;
}): void {
  const {bundleId, stepId, stepType, isRedirect} = params;
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.set('bundle', bundleId);
  newUrl.searchParams.set('step', stepId);
  newUrl.pathname = `/me/bundle/${stepType}`;

  if (isRedirect) {
    window.history.replaceState(null, '', newUrl.toString());
  } else {
    navigateToUrl(newUrl.toString());
  }
}

export function goToPage(page = '/me/home', redirectReason?: string) {
  const newUrl = new URL(window.location.href);
  // Clear any search params
  newUrl.search = '';
  if (redirectReason) {
    newUrl.searchParams.set('redirectReason', redirectReason);
  }
  newUrl.pathname = page;
  navigateToUrl(newUrl.toString());
}

/**
 * Determines whether the URL given is an internal Verily Me Web URL. If so, returns the relative
 * URL slug.
 *
 * @param {string} url - The URL to check.
 *
 * @returns {string} - Returns relative internal URL slug, otherwise empty string for external URL.
 */
export function getInternalUrl(url: string): string {
  const internalDomain = 'app.verilyme.com';
  const urlObj = new URL(url);

  if (urlObj.hostname.includes(internalDomain)) {
    return urlObj.pathname + urlObj.search + urlObj.hash;
  }
  return '';
}
