/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/bundle/bff/api/v1/logger_service.proto" (package "verily_me.web.bundle.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { LoggerService } from "./logger_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Empty } from "../../../../../../google/protobuf/empty";
import type { LogErrorRequest } from "./logger_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service verily_me.web.bundle.bff.api.v1.LoggerService
 */
export interface ILoggerServiceClient {
    /**
     * Logging error message coming from MFE.
     *
     * @generated from protobuf rpc: LogError(verily_me.web.bundle.bff.api.v1.LogErrorRequest) returns (google.protobuf.Empty);
     */
    logError(input: LogErrorRequest, options?: RpcOptions): UnaryCall<LogErrorRequest, Empty>;
}
/**
 * @generated from protobuf service verily_me.web.bundle.bff.api.v1.LoggerService
 */
export class LoggerServiceClient implements ILoggerServiceClient, ServiceInfo {
    typeName = LoggerService.typeName;
    methods = LoggerService.methods;
    options = LoggerService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Logging error message coming from MFE.
     *
     * @generated from protobuf rpc: LogError(verily_me.web.bundle.bff.api.v1.LogErrorRequest) returns (google.protobuf.Empty);
     */
    logError(input: LogErrorRequest, options?: RpcOptions): UnaryCall<LogErrorRequest, Empty> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<LogErrorRequest, Empty>("unary", this._transport, method, opt, input);
    }
}
