export type UrlChangeHandler = (location: Location) => Promise<void> | void;

export class UrlChangeObserver {
  private previousUrl: string;
  private handlers: UrlChangeHandler[];

  constructor() {
    this.handlers = [];
  }

  async onObservation() {
    if (window.location.href === this.previousUrl) {
      return;
    }

    this.previousUrl = window.location.href;

    for (const handler of this.handlers) {
      handler(window.location);
    }
  }

  addHandler(handler: UrlChangeHandler) {
    this.handlers.push(handler);
  }

  async observe() {
    await this.onObservation();

    const observer = new MutationObserver(() => this.onObservation());
    const config = {subtree: true, childList: true};
    observer.observe(document, config);
  }
}
