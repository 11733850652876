import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {
  ErrorMessage,
  LogErrorRequest,
  LogErrorRequest_Severity,
} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/logger_service';
import {LoggerServiceClient} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/logger_service.client';

let host = '/api';

if (process.env.NODE_ENV === 'local-development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '3000';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
}

/**
 * LoggingService is a class responsible for logging errors.
 * Note: this is an MVP-only solution. Post-MVP this will be moved
 * to PHAF.
 */
class LoggingService {
  private loggingServiceClient;

  async getClient(): Promise<LoggerServiceClient> {
    if (!this.loggingServiceClient) {
      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
      });

      this.loggingServiceClient = new LoggerServiceClient(transport);
    }

    return this.loggingServiceClient;
  }

  /**
   * Logs an error using the LoggerServiceClient.
   *
   * @param {string} name - The name of the error.
   * @param {string} stack - The stack trace of the error.
   * @param {string} url - The URL where the error occurred.
   * @param {string} componentName - The name of the component where the error occurred.
   * @param {LogErrorRequest_Severity} severity - GCP log severity.
   * @returns {Promise<void>} A promise that resolves when the error is successfully logged.
   */
  async logError(
    name: string,
    stack: string,
    url: string,
    componentName: string,
    severity: LogErrorRequest_Severity
  ): Promise<void> {
    const message: ErrorMessage = {name, stack, url};
    const request: LogErrorRequest = {message, componentName, severity};

    this.getClient()
      .then(client => client.logError(request))
      .catch(err =>
        console.error(`Error occurred while logging error: ${err}`)
      );
  }
}

export default new LoggingService();
