import {
  MethodInfo,
  NextUnaryFn,
  RpcInterceptor,
  RpcOptions,
  UnaryCall,
} from '@protobuf-ts/runtime-rpc';

class LogGrpcUnaryInterceptor implements RpcInterceptor {
  interceptUnary(
    next: NextUnaryFn,
    method: MethodInfo,
    input: object,
    options: RpcOptions
  ): UnaryCall {
    const result = next(method, input, options);
    console.log(`${result.method.name} gRPC call:`, result);

    return result;
  }
}

export {LogGrpcUnaryInterceptor};
